import { Model } from "@vuex-orm/core";
import Project from "./Project";
import Instrument from "./Instrument";
import Valve from "./Valve";
import PressureRating from "./PressureRating";

export default class ValveSpec extends Model {
  static entity = "valveSpecs";
  static url = '/valveSpec'
  static friendlyName = "Valve spec"

  static state() {
    return {
      icon: "mdi-flask-outline"
    };
  }

  static fields() {
    return {
      id: this.attr(0),
      name: this.attr(null),
      componentType: this.attr(null),
      valveType: this.attr(null),
      manufacturer: this.attr(null),
      model: this.attr(null),
      connectionType: this.attr(null),
      operatorType: this.attr(null),
      materialBody: this.attr(null),
      materialSeat: this.attr(null),
      bore: this.attr(null),
      // ioType: this.attr(null),
      remarks: this.attr(null),
      attributes: this.attr(null),
      state: this.attr(null),
      revision: this.attr(null),
      lastChangedBy:this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      pressureRatingId: this.attr(null),
      pressureRating: this.belongsTo(PressureRating, "pressureRatingId"),
      projectId: this.attr(null),
      instruments: this.hasMany(Instrument, "valveSpecId"),
      valves: this.hasMany(Valve, "valveSpecId"),
      projectId: this.attr(null),
      project: this.belongsTo(Project, "projectId"),
      validFrom: this.attr(null)
    };
  }

  get tag() {
    return `${this.name}`;
  }
}
